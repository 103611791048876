import { render, staticRenderFns } from "./protectionPlan.vue?vue&type=template&id=005958ce&"
import script from "./protectionPlan.vue?vue&type=script&lang=js&"
export * from "./protectionPlan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins/workspace/e-commerce-development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('005958ce')) {
      api.createRecord('005958ce', component.options)
    } else {
      api.reload('005958ce', component.options)
    }
    module.hot.accept("./protectionPlan.vue?vue&type=template&id=005958ce&", function () {
      api.rerender('005958ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/helps/protectionPlan.vue"
export default component.exports